import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import {connect} from 'react-redux';

import ControlsComponent from '../components/controls/controls.jsx';

class Controls extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleGreenFlagClick',
            'handleStopAllClick',
            'handleCameraClick',
            'handleMicClick',
            'handleExtensionAdded'
        ]);
        this.state = {
            isCameraOn: false,
            isCameraConnected: false,
            isMicOn: false,
            isMicConnected: this.props.vm.runtime.ioDevices.audio.isEnable
        };
    }

    componentDidMount () {
        this.props.vm.addListener('EXTENSION_ADDED', this.handleExtensionAdded);
    }
    componentWillUnmount () {
        this.props.vm.removeListener('EXTENSION_ADDED', this.handleExtensionAdded);
    }

    handleExtensionAdded (extension) {
        if (extension.id === 'videoSensing') {
            const detectInterval = setInterval(() => {
                if (this.props.vm.runtime.ioDevices.video.videoReady){
                    this.setState({
                        isCameraConnected: true,
                        isCameraOn: true
                    });
                    clearInterval(detectInterval);
                }
            }, 300);
        }

        if (extension.id === 'recognition' || extension.id === 'teachableMachine') {
            this.props.vm.runtime.ioDevices.audio.enableAudio();
            const detectInterval = setInterval(() => {
                if (this.props.vm.runtime.ioDevices.audio.isEnable){
                    this.setState({
                        isMicOn: true,
                        isMicConnected: true
                    });
                    clearInterval(detectInterval);
                }
            }, 300);
        }
    }

    handleGreenFlagClick (e) {
        e.preventDefault();
        if (e.shiftKey) {
            this.props.vm.setTurboMode(!this.props.turbo);
        } else {
            if (!this.props.isStarted) {
                this.props.vm.start();
            }
            this.props.vm.greenFlag();
        }
    }

    handleStopAllClick (e) {
        e.preventDefault();
        this.props.vm.stopAll();
    }

    handleCameraClick (e) {
        e.preventDefault();
        if (this.state.isCameraOn) {
            this.props.vm.runtime.ioDevices.video.disableVideo();
        } else {
            this.props.vm.runtime.ioDevices.video.enableVideo();
        }
        this.setState({isCameraOn: !this.state.isCameraOn});
    }

    handleMicClick (e) {
        e.preventDefault();
        if (this.state.isMicOn) {
            this.props.vm.runtime.ioDevices.audio.disableAudio();
        } else {
            this.props.vm.runtime.ioDevices.audio.enableAudio();
        }
        this.setState({isMicOn: !this.state.isMicOn});
    }

    render () {
        const {
            vm, // eslint-disable-line no-unused-vars
            isStarted, // eslint-disable-line no-unused-vars
            projectRunning,
            turbo,
            ...props
        } = this.props;
        return (
            <ControlsComponent
                {...props}
                active={projectRunning}
                turbo={turbo}
                onGreenFlagClick={this.handleGreenFlagClick}
                onStopAllClick={this.handleStopAllClick}
                onCameraClick={this.handleCameraClick}
                onMicClick={this.handleMicClick}
                isCameraConnected={this.state.isCameraConnected}
                isCameraOn={this.state.isCameraOn}
                isMicConnected={this.state.isMicConnected}
                isMicOn={this.state.isMicOn}
            />
        );
    }
}

Controls.propTypes = {
    isStarted: PropTypes.bool.isRequired,
    projectRunning: PropTypes.bool.isRequired,
    turbo: PropTypes.bool.isRequired,
    vm: PropTypes.instanceOf(VM)
};

const mapStateToProps = state => ({
    isStarted: state.scratchGui.vmStatus.running,
    projectRunning: state.scratchGui.vmStatus.running,
    turbo: state.scratchGui.vmStatus.turbo
});
// no-op function to prevent dispatch prop being passed to component
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
