import sprites from './sprites.json';

function getExportingSprites () {
    try {
        if (!build.env.HIDE_UNOFFICIAL_COSTUMES) {
            return sprites;
        }
    } catch (e) {
        console.warn('build.env.HIDE_UNOFFICIAL_COSTUMES may not be defined.', e);
        return sprites;
    }

    let officialSprites = [];
    for (const sprite of sprites) {
        if (sprite.costumes.every(costume => costume.unofficialCostume)) {
            continue;
        }
        if (sprite.costumes.some(costume => costume.unofficialCostume)) {
            officialSprites.push({
                ...sprite,
                costumes: sprite.costumes.filter(costume => !costume.unofficialCostume)
            });
        } else {
            officialSprites.push(sprite);
        }
    }
    return officialSprites;
}

const exportingSprites = getExportingSprites();

export default exportingSprites;
