const SET_HIDDEN_SPRITE_FROM_STAGE = 'malrang-tutorial/SET_HIDDEN_SPRITE_FROM_STAGE';
const OPEN_SUCCESS_DIALOG = 'malrang-tutorial/OPEN_SUCCESS_DIALOG';
const CLOSE_SUCCESS_DIALOG = 'malrang-tutorial/CLOSE_SUCCESS_DIALOG';
const OPEN_FAIL_DIALOG = 'malrang-tutorial/OPEN_FAIL_DIALOG';
const CLOSE_FAIL_DIALOG = 'malrang-tutorial/CLOSE_FAIL_DIALOG';
const SHOW_ALL_BLOCK_CATEGORIES = 'malrang-tutorial/SHOW_ALL_BLOCK_CATEGORIES';
const SET_MAXIMUM_GREEN_FLAG_CLICK_COUNT = 'malrang-tutorial/SET_MAXIMUM_GREEN_FLAG_CLICK_COUNT';

const initialState = {
    hiddenSpriteIdsFromStage: new Set(),
    openSuccessDialog: false,
    openFailDialog: false,
    showAllBlockCategories: true,
    maximumGreenFlagClickCount: null,
}

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_HIDDEN_SPRITE_FROM_STAGE:
            const hiddenSpriteIdsFromStage = new Set(state.hiddenSpriteIdsFromStage);
            const hidden = hiddenSpriteIdsFromStage.has(action.spriteId);
            if (hidden) {
                hiddenSpriteIdsFromStage.delete(action.spriteId);
            } else {
                hiddenSpriteIdsFromStage.add(action.spriteId);
            }
            return {
                ...state,
                hiddenSpriteIdsFromStage
            }
        case OPEN_SUCCESS_DIALOG:
            return {
                ...state,
                openSuccessDialog: true
            };
        case CLOSE_SUCCESS_DIALOG:
            return {
                ...state,
                openSuccessDialog: false
            };
        case OPEN_FAIL_DIALOG:
            return {
                ...state,
                openFailDialog: true
            };
        case CLOSE_FAIL_DIALOG:
            return {
                ...state,
                openFailDialog: false
            };
        case SHOW_ALL_BLOCK_CATEGORIES:
            return {
                ...state,
                showAllBlockCategories: action.showAllBlockCategories
            };
        case SET_MAXIMUM_GREEN_FLAG_CLICK_COUNT:
            return {
                ...state,
                maximumGreenFlagClickCount: action.maximumGreenFlagClickCount
            };
        default:
            return state;
    }
};

const setHiddenSpriteFromStage = (spriteId) => ({
    type: SET_HIDDEN_SPRITE_FROM_STAGE,
    spriteId
});

const openSuccessDialog = () => ({
    type: OPEN_SUCCESS_DIALOG
})

const closeSuccessDialog = () => ({
    type: CLOSE_SUCCESS_DIALOG
})

const openFailDialog = () => ({
    type: OPEN_FAIL_DIALOG
})

const closeFailDialog = () => ({
    type: CLOSE_FAIL_DIALOG
})

const setShowAllBlockCategories = (showAllBlockCategories) => ({
    type: SHOW_ALL_BLOCK_CATEGORIES,
    showAllBlockCategories
});

const setMaximumGreenFlagClickCount = (maximumGreenFlagClickCount) => ({
    type: SET_MAXIMUM_GREEN_FLAG_CLICK_COUNT,
    maximumGreenFlagClickCount
});

export {
    reducer as default,
    initialState as malrangTutorialInitialState,
    setHiddenSpriteFromStage,
    openSuccessDialog,
    closeSuccessDialog,
    openFailDialog,
    closeFailDialog,
    setShowAllBlockCategories,
    setMaximumGreenFlagClickCount
};