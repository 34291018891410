const STARTING_SCREEN_VISIBLE_STATE = 'scratch-gui/starting-screen-visible-state/STARTING_SCREEN_VISIBLE_STATE';

let initialState = false;

try {
    initialState = build.env.SHOW_STARTING_SCREEN ?? false;
} catch (e) {
    console.warn('build.env.SHOW_STARTING_SCREEN may not be defined.', e);
    initialState = false;
}

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case STARTING_SCREEN_VISIBLE_STATE:
        return action.visible;
    default:
        return state;
    }
};

const setStartingScreenVisibleState = function (visible) {
    return {
        type: STARTING_SCREEN_VISIBLE_STATE,
        visible: visible
    };
};
    

export {
    reducer as default,
    initialState as startingScreenVisibleState,
    setStartingScreenVisibleState
};
