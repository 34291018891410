import Modal from '../../containers/modal.jsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import blockStyles from '../blocks/blocks.css';
import SpriteList from '../sprite-selector/sprite-list.jsx';
import { SPRITE_LIST_TYPE } from '../../lib/constant/malrangTutorial.js';
import styles from './malrang-tutorial-modal.css';
import classNames from 'classnames';
import Box from '../box/box.jsx';
import MalrangTutorialGuideTip from './malrang-tutorial-guide-tip.jsx';
import MalrangTutorialGuideWarning from './malrang-tutorial-warning.jsx';
import SaveTutorialSb3 from './save-tutorial-sb3.jsx';
import RestrictionOptions from './restriction-options.jsx';
import CheckButton from '../check-button/check-button.jsx';
import useBlockStacks from './hooks/useBlockStacks.js';

const preventClick = (event) => {
    event.stopPropagation();
}

const MalrangTutorialModalComponent = ({ 
    onRequestClose,
    showAllBlockCategories,
    toggleShowAllBlockCategories,
    toolbox,
    palette,
    paletteScrollbar,
    updateBlocksFiltering,
    editingTarget,
    hoveredTarget,
    sprites,
    raised,
    selectedId,
    onDrop,
    isSpriteHidden,
    setSpriteVisibleOnTargetPane,
    initialSpriteId,
    setInitialSprite,
    shouldPreventDragSprite,
    shouldPreventEditSprite,
    shouldPreventAddSprite,
    shouldPreventDeleteSprite,
    shouldPreventSelectSprite,
    shouldHideCostumeTab,
    shouldHideSoundTab,
    shouldHideExtensionButton,
    shouldHideFileMenu,
    shouldHideEditMenu,
    isEverySpriteConfigsChecked,
    isEveryStageConfigsChecked,
    isEveryMenuConfigsChecked,
    onRequestDownloadProject,
    shouldPreventClickStage,
    shouldPreventAddStage,
    onSaveFinished,
    maximumGreenFlagClickCount,
    blockSvgGroups,
    hiddenBlockIdsInWorkspace,
    eventHandler,
    flyout
}) => {
    const blockStacksListRef = useRef(null);

    useBlockStacks(
        blockSvgGroups,
        blockStacksListRef,
        eventHandler.onClickHideBlockStackCheckbox,
        hiddenBlockIdsInWorkspace
    );

    const paletteRef = useCallback((node) => {
        if (node === null) return;
        node.appendChild(toolbox);
        node.appendChild(palette);
        node.appendChild(paletteScrollbar);
    }, []);

    useEffect(() => {
        const flyoutWorkspace = flyout.getElementsByClassName('blocklyWorkspace')[0];
        flyoutWorkspace.setAttribute('clip-path', '');
        return () => {
            flyoutWorkspace.style.clipPath = '';
        };
    }, []);

    useEffect(() => {
        flyout.addEventListener('mousedown', preventClick, true);
        flyout.style.overflow = 'hidden';

        return () => {
            flyout.removeEventListener('mousedown', preventClick, true);
            flyout.style.overflow = '';
        }
    }, []);

    const ref = useRef(null);

    const handleFlyoutResize = () => {
        if(ref.current === null) {
            return;
        }

        const path = flyout.querySelector('.blocklyFlyoutBackground');
        
        const modalContentHeight = ref.current.offsetHeight;
        const d = path.getAttribute('d');

        path.setAttribute('d', d.replace(/v\s*\d+/, `v ${modalContentHeight}`));
        flyout.setAttribute('height', `${modalContentHeight}`);
        toolbox.setAttribute('style', `height: ${modalContentHeight}px !important;`);
    };

    useEffect(() => {
        window.addEventListener('resize', handleFlyoutResize);

        return () => {
            window.removeEventListener('resize', handleFlyoutResize);
        };
    }, []);

    const onUpdate = () => {
        updateBlocksFiltering();

        setTimeout(() => {
            handleFlyoutResize();
        }, 100);
    };

    const onClickShowAllBlockCategories = () => {
        toggleShowAllBlockCategories();
        handleFlyoutResize();
    }

    return (<Modal
            fullScreen
            onRequestClose={onRequestClose}
            contentLabel={'튜토리얼 설정 및 저장'}
            boxClassName={styles.modalFlex}
        >
            <div className={styles.modalContent} ref={ref}>
                <div className={styles.paletteContainer}>
                    <div
                        className={blockStyles.blocks}
                        ref={paletteRef}
                    />
                </div>
                <div className={styles.configContainer}>
                    <div className={styles.infoContainer}>
                        <MalrangTutorialGuideWarning />
                        <SaveTutorialSb3 onSaveFinished={onSaveFinished} onRequestDownloadProject={onRequestDownloadProject} />
                    </div>

                    <div className={styles.infoContainer}>
                        <MalrangTutorialGuideTip />
                        <SaveTutorialSb3 onSaveFinished={onSaveFinished} onRequestDownloadProject={onRequestDownloadProject} />
                    </div>
                    <div className={classNames(styles.container, styles.blockOptionContainer)}>
                        <div style={{display: 'flex', justifyContent:'space-between'}}>
                            <div className={styles.blockOptionTitle}>튜토리얼 카테고리,블록 설정</div>
                            <div className={styles.showAllBlockContainer}>
                                <CheckButton
                                    className={classNames(styles.restrictionCheckbox, showAllBlockCategories ? styles.restrictionLabelChecked : styles.restrictionLabel)}
                                    onClick={onClickShowAllBlockCategories}
                                    checked={showAllBlockCategories}
                                    label="전체 블록 카테고리 보이기"
                                />
                            </div>
                        </div>
                        <div className={styles.blockOptionDescription}>
                            업데이트 버튼 클릭 시 현재 설정한 코딩 기준으로 튜토리얼에 표시될 블록 카테고리와 블록이 설정됩니다.
                        </div>
                        
                        <div className={styles.blockOptionDescriptionGray}>
                            <span>[전체 블록 카테고리 보이기]</span>
                            를 체크 하면 해당 기능은 사용할 수 없습니다. 
                        </div>
                        
                        <button onClick={onUpdate} className={classNames(styles.downloadSb3Button, styles.updateButton)}>
                            업데이트
                        </button>
                    </div>

                    <div className={styles.optionListContainer}>
                        <div className={styles.listVertical}>
                            <div className={styles.container}>
                                <h2>스프라이트 숨기기</h2>
                                <h4>숨기기를 체크하면 튜토리얼에서 스프라이트가 동작하지만 눈에는 보이지 않습니다.</h4>
                                <Box className={classNames(styles.spriteSelector, styles.fullyBorderRadius)}>
                                    <SpriteList
                                        editingTarget={editingTarget}
                                        hoveredTarget={hoveredTarget}
                                        items={Object.keys(sprites).map(id => sprites[id])}
                                        raised={raised}
                                        selectedId={selectedId}
                                        onDrop={onDrop}
                                        isSpriteHidden={isSpriteHidden}
                                        setSpriteVisibleOnTargetPane={setSpriteVisibleOnTargetPane}
                                        type={SPRITE_LIST_TYPE.HIDE}
                                        inMalrangModal={true}
                                    />
                                </Box>
                            </div>
                            <div className={styles.container}>
                                <h2>시작 스프라이트</h2>
                                <h4>튜토리얼 시작 시 선택되어 있는 스프라이트를 설정합니다.</h4>
                                <Box className={classNames(styles.spriteSelector, styles.fullyBorderRadius)}>
                                    <SpriteList
                                        editingTarget={editingTarget}
                                        hoveredTarget={hoveredTarget}
                                        items={Object.keys(sprites).map(id => sprites[id])}
                                        raised={raised}
                                        selectedId={selectedId}
                                        onDrop={onDrop}
                                        initialSpriteId={initialSpriteId}
                                        setInitialSprite={setInitialSprite}
                                        type={SPRITE_LIST_TYPE.SET_INITIAL}
                                        inMalrangModal={true}
                                    />
                                </Box>
                            </div>
                            <div className={styles.container}>
                                <h2>블록 숨기기</h2>
                                <h4>튜토리얼에서 숨겨질 블록을 선택합니다.</h4>
                                <div ref={blockStacksListRef} className={styles.blockStacksList} />
                            </div>
                        </div>
                        <div className={classNames(styles.container, styles.listVertical, styles.restrictionContainer)}>
                            <RestrictionOptions 
                                isEveryConfigsChecked={isEveryMenuConfigsChecked}
                                onClickSelectAll={eventHandler.onUpdateBatchSelectionMenuConfigs}
                                title="탭 / 메뉴 숨기기"
                                restrictionOptions={[
                                    {
                                        id: 'preventCostumeTab',
                                        isChecked: shouldHideCostumeTab,
                                        onChange: eventHandler.onUpdateHideCostumeTab,
                                        label: '형태',
                                    },
                                    {
                                        id: 'preventSoundTab',
                                        isChecked: shouldHideSoundTab,
                                        onChange: eventHandler.onUpdateHideSoundTab,
                                        label: '소리',
                                    },
                                    {
                                        id: 'preventExtension',
                                        isChecked: shouldHideExtensionButton,
                                        onChange: eventHandler.onUpdateHideExtensionButton,
                                        label: '확장블록',
                                    },
                                    {
                                        id: 'preventFileMenu',
                                        isChecked: shouldHideFileMenu,
                                        onChange: eventHandler.onUpdateHideFileMenu,
                                        label: '파일',
                                    },
                                    {
                                        id: 'preventEditMenu',
                                        isChecked: shouldHideEditMenu,
                                        onChange: eventHandler.onUpdateHideEditMenu,
                                        label: '편집',
                                    }
                                ]}
                            />
                            
                            <RestrictionOptions
                                isEveryConfigsChecked={isEveryStageConfigsChecked}
                                onClickSelectAll={eventHandler.onUpdateBatchSelectionStageConfigs}
                                title="배경"
                                restrictionOptions={[
                                    {
                                        id: 'preventClickStage',
                                        isChecked: shouldPreventClickStage,
                                        onChange: eventHandler.onClickPreventStageCheckbox,
                                        label: '포커싱',

                                    },
                                    {
                                        id: 'preventAddStage',
                                        isChecked: shouldPreventAddStage,
                                        onChange: eventHandler.onClickAddStageCheckbox,
                                        label: '추가',

                                    }
                                ]}
                            />
                            
                            <RestrictionOptions
                                isEveryConfigsChecked={isEverySpriteConfigsChecked}
                                onClickSelectAll={eventHandler.onUpdateBatchSelectionSpriteConfigs}
                                title="스프라이트"
                                restrictionOptions={[
                                    {
                                        id: 'preventDragSprite',
                                        isChecked: shouldPreventDragSprite,
                                        onChange: eventHandler.onUpdatePreventDragSprite,
                                        label: '드래그',
                                    },
                                    {
                                        id: 'preventEditSprite',
                                        isChecked: shouldPreventEditSprite,
                                        onChange: eventHandler.onUpdatePreventEditSprite,
                                        label: '수정',
                                    },
                                    {
                                        id: 'preventAddSprite',
                                        isChecked: shouldPreventAddSprite,
                                        onChange: eventHandler.onUpdatePreventAddSprite,
                                        label: '추가',
                                    },
                                    {
                                        id: 'preventRemoveSprite',
                                        isChecked: shouldPreventDeleteSprite,
                                        onChange: eventHandler.onUpdatePreventDeleteSprite,
                                        label: '삭제',
                                    },
                                    {
                                        id: 'preventSelectSprite',
                                        isChecked: shouldPreventSelectSprite,
                                        onChange: eventHandler.onUpdatePreventSelectSprite,
                                        label: '포커싱',
                                    },
                                ]}
                            />

                            <div>
                                <h2 classNames={styles.restrictionTitle}>
                                    최대 깃발 클릭 횟수
                                </h2>
                                <input
                                    value={maximumGreenFlagClickCount}
                                    type="text"
                                    onChange={eventHandler.onChangeMaximumGreenFlagInput}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
};

export default MalrangTutorialModalComponent;
