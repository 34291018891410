import React from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import VM from 'scratch-vm';
import ConnectionCheckAlertComponent from '../components/connection-check-alert/connection-check-alert.jsx';
import {setConnectionModalExtensionId} from '../reducers/connection-modal';
import {connect} from 'react-redux';
import {openConnectionModal} from '../reducers/modals.js';

class ConnectionCheckAlert extends React.Component {

    constructor (props) {
        super(props);
        
        bindAll(this, [
            'handleConnected',
            'handleDisconnected',
            'handleClickAlert',
            'handleClickCloseButton',
            'handleHideAlerIfExtensionRemoved'
        ]);

        this.state = {
            extensionId: undefined,
            isAlertVisible: false,
            alertShouldHide: false,
            deviceState: undefined
        };
    }

    componentDidMount () {
        this.props.vm.addListener('PERIPHERAL_CONNECTED', this.handleConnected);
        this.props.vm.addListener('PERIPHERAL_DISCONNECTED', this.handleDisconnected);
        this.props.vm.addListener('REMOVE_EXTENSION', this.handleHideAlerIfExtensionRemoved);
    }
    componentWillUnmount () {
        this.props.vm.removeListener('PERIPHERAL_CONNECTED', this.handleConnected);
        this.props.vm.removeListener('PERIPHERAL_DISCONNECTED', this.handleDisconnected);
        this.props.vm.removeListener('REMOVE_EXTENSION', this.handleHideAlerIfExtensionRemoved);
    }

    isRemovedExtension (extensionId) {
        return extensionId === this.props.extensionId;
    }

    handleHideAlerIfExtensionRemoved (extensionId) {
        if (this.isRemovedExtension(extensionId)){
            this.setState({
                isAlertVisible: false,
                alertShouldHide: true
            });
        }
    }

    handleConnected (extensionId) {
        const peripheralExtensionIds = Object.keys(this.props.vm.runtime.peripheralExtensions);
        if (!extensionId || !peripheralExtensionIds.includes(extensionId)) {
            extensionId = peripheralExtensionIds.find(id => this.props.vm.getPeripheralIsConnected(id));
        }
        this.setState({
            extensionId,
            isAlertVisible: true,
            alertShouldHide: false,
            deviceState: 'connected'
        });

        setTimeout(() => {
            // 3초가 지나기 전에 연결을 끊는경우에 alert이 꺼지는것을 방지
            if (this.state.deviceState === 'connected'){
                this.setState({isAlertVisible: false});
            }
        }, 3000);
    }

    handleDisconnected () {
        if (this.state.alertShouldHide){
            return;
        }
        
        this.setState({
            isAlertVisible: true,
            deviceState: 'disconnected'
        });
    }

    handleClickAlert () {
        if (this.state.deviceState === 'connected'){
            this.setState({isAlertVisible: false});
        } else {
            // 연결 모달창 띄우기
            this.props.onOpenConnectionModal(this.props.extensionId);
        }
    }

    handleClickCloseButton () {
        if (this.state.deviceState === 'connected'){
            this.setState({
                isAlertVisible: false
            });
        } else {
            this.setState({
                isAlertVisible: false,
                alertShouldHide: true
            });
        }
    }

    render () {
        if (this.state.isAlertVisible){
            return (
                <ConnectionCheckAlertComponent
                    extensionId={this.state.extensionId}
                    deviceState={this.state.deviceState}
                    onClickAlert={this.handleClickAlert}
                    onClickCloseButton={this.handleClickCloseButton}
                />
            );
        }
        return (<></>);
    }
}

ConnectionCheckAlert.propTypes = {
    vm: PropTypes.instanceOf(VM),
    extensionId: PropTypes.string,
    onOpenConnectionModal: PropTypes.func
};

const mapStateToProps = state => ({
    extensionId: state.scratchGui.connectionModal.extensionId
});

const mapDispatchToProps = dispatch => ({
    onOpenConnectionModal: id => {
        dispatch(setConnectionModalExtensionId(id));
        dispatch(openConnectionModal());
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectionCheckAlert);
