import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';

import styles from './teachable-machine.css';

const IS_IN_SEMOBAE = !!window.semobaeAPI;

const TeachableMachineComponent = props => (
    <Modal
        id="teachableMachineModal"
        className={styles.modalContent}
        contentLabel={props.title}
        onRequestClose={props.onCancel}
    >
        <Box className={styles.body}>
            <Box className={styles.label}>
                {props.label}
            </Box>
            <Box>
                <input
                    autoFocus
                    className={styles.variableNameTextInput}
                    placeholder={props.placeholder}
                    name={props.label}
                    onChange={props.onChange}
                />
                {props.isAudioModel && (
                    <div className={styles.audioModelAlertMessage}>죄송합니다. 오디오 모델 학습은 점검 중입니다. 빠르게 처리해 사용할 수 있도록 하겠습니다.</div>
                )}
            </Box>
            <Box className={styles.officialModelContainer}>
                <input
                    type='checkbox'
                    className={styles.officialModelCheckbox} 
                    onChange={props.handleCheckOfficialModel}
                />
                공식 티처블머신 모델
            </Box>
            <Box className={styles.buttonRow}>
                <button
                    className={styles.makeModelButton}
                    onClick={() => {
                        if (IS_IN_SEMOBAE) {
                            window.semobaeAPI.openExternal('https://teachablemachine.withgoogle.com');
                        } else {
                            window.open('https://teachablemachine.withgoogle.com', '_blank');
                        }
                    }}
                >
                    <FormattedMessage
                        defaultMessage="Make model"
                        description="Button for creating a model with Teachable Machine"
                        id="gui.prompt.makeModel"
                    />
                </button>
                <button
                    className={styles.cancelButton}
                    onClick={props.onCancel}
                >
                    <FormattedMessage
                        defaultMessage="Cancel"
                        description="Button in prompt for cancelling the dialog"
                        id="gui.prompt.cancel"
                    />
                </button>
                <button
                    className={styles.okButton}
                    onClick={props.onOk}
                >
                    <FormattedMessage
                        defaultMessage="OK"
                        description="Button in prompt for confirming the dialog"
                        id="gui.prompt.ok"
                    />
                </button>
            </Box>
        </Box>
    </Modal>
);

TeachableMachineComponent.propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
};

export default TeachableMachineComponent;
