const SEMOBAE_USER_TYPE = Object.freeze({
    TEACHER: 1,
    STUDENT: 2,
    SCHOOL_MANAGER: 3,
    CONTENT_CREATOR: 4,
    SYSTEM_OPERATOR: 5,
});

const SET_SEMOCLASS_PARAMS = 'semobae/SET_SEMOCLASS_PARAMS';
const SET_SHOULD_PREVENT_SET_SEMOCLASS_PARAMS = 'semobae/SET_SHOULD_PREVENT_SET_SEMOCLASS_PARAMS';

const initialState = {
    semoclassParams: null,
    studyFileName: null,
    currentChapterNumber: null,
    codingTemplateUrl: null,
    isStudent: false,
    shouldPreventSetSemoclassParams: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_SEMOCLASS_PARAMS:
        if (!action.params) {
            return initialState;
        }

        if (state.shouldPreventSetSemoclassParams && !action.force) {
            return state;
        }

        const semoclass = action.params.semoclass;
        const currentChapter = action.params.currentChapter;

        let codingTemplateUrl = null;
        if (currentChapter && currentChapter.codingTemplateUrl) {
            codingTemplateUrl = currentChapter.codingTemplateUrl;
        } else if (semoclass.codingTemplateUrl) {
            codingTemplateUrl = semoclass.codingTemplateUrl;
        } else if (action.params.codingTemplateUrl) {
            codingTemplateUrl = action.params.codingTemplateUrl;
        }
        return Object.assign({}, state, {
            semoclassParams: action.params,
            studyFileName: getStudyFileName(action.params),
            semoclassExtensions: getSemoclassExtensions(action.params),
            codingTemplateUrl,
            currentChapterNumber: currentChapter ? currentChapter.chapterNumber : null,
            isStudent: action.params.user.type === SEMOBAE_USER_TYPE.STUDENT,
            assignmentId: action.params.assignmentId,
            shouldPreventSetSemoclassParams: !!action.params.isMalrangTutorial,
        });
    case SET_SHOULD_PREVENT_SET_SEMOCLASS_PARAMS:
        return Object.assign({}, state, {
            shouldPreventSetSemoclassParams: action.shouldPrevent
        });
    default:
        return state;
    }
};

const setSemoclassParams = params => ({
    type: SET_SEMOCLASS_PARAMS,
    params: params
});

const forceSetSemoclassParams = params => ({
    type: SET_SEMOCLASS_PARAMS,
    params: params,
    force: true
});

const setShouldPreventSetSemoclassParams = shouldPrevent => ({
    type: SET_SHOULD_PREVENT_SET_SEMOCLASS_PARAMS,
    shouldPrevent: shouldPrevent
});

function getStudyFileName (semoclassParams) {
    if (!semoclassParams) {
        return null;
    }
    const semoclassId = semoclassParams.semoclass.id;
    const userId = semoclassParams.user.id;
    let fileName = `${userId}_${semoclassId}`;
    if (semoclassParams.currentChapter) {
        fileName += `_${semoclassParams.currentChapter.title}`;
    }
    const extensionName = semoclassParams.extensionName.replace('@semobae-ex/', '');
    fileName += `_${extensionName}.sb3`;
    return fileName;
}

function getSemoclassExtensions (semoclassParams) {
    if (!semoclassParams) {
        return [];
    }
    const semoclass = semoclassParams.semoclass;
    return semoclass.extensions;
}

export {
    reducer as default,
    initialState as semobaeInitialState,
    setSemoclassParams,
    setShouldPreventSetSemoclassParams,
    forceSetSemoclassParams,
};
