import React from 'react';
import styles from './malrang-tutorial-modal.css';
import tipTail from './tiptail.svg';

const MalrangTutorialGuideTip = () => {
    return <div className={styles.tipContainer}>
            <p className={styles.tip}>Tip!</p>
            <div className={styles.tipMessage}>
                <p>1. "튜토리얼 미션 [성공/실패] 신호 보내기" 블록으로 미션을 성공, 실패 시킬 수 있습니다.</p>
                <p>2. "내 블록"의 블록 생성으로 블록을 만든 경우 정의하기 블록은 학생에게 보이지 않습니다.</p>
            </div>
            <img src={tipTail} className={styles.tipTail}/>
        </div>

};

export default MalrangTutorialGuideTip;