import React from 'react';

import styles from './malrang-tutorial-modal.css';
import CheckButton from '../check-button/check-button.jsx';
import classNames from 'classnames';

const RestrictionOptions = ({
    title,
    isEveryConfigsChecked,
    onClickSelectAll,
    restrictionOptions,
}) => {
    return (
        <div className={styles.restrictionOption}>
            <div className={styles.listHorizontal}>
                <h2 className={styles.restrictionTitle}>{title}</h2>
                <div className={styles.restrictionAllCheck}>
                    <CheckButton
                        className={classNames(styles.restrictionCheckbox, isEveryConfigsChecked ? styles.restrictionLabelChecked : styles.restrictionLabel)}
                        onClick={onClickSelectAll}
                        checked={isEveryConfigsChecked}
                        label="전체 선택"
                    />
                </div>
            </div>
            <div className={styles.restrictionCheckboxContainer}>
            {
                restrictionOptions.map((option, index) => (
                    <div key={index}>
                        <CheckButton
                            className={classNames(styles.restrictionCheckbox, option.isChecked ? styles.restrictionLabelChecked : styles.restrictionLabel)}
                            onClick={option.onChange}
                            checked={option.isChecked}
                            label={option.label}
                        />
                    </div>
                ))
            }
            </div>
        </div>
    )
};

export default RestrictionOptions;