import backdrops from './backdrops.json';

function getExportingBackdrops () {
    try {
        if (!build.env.HIDE_UNOFFICIAL_COSTUMES) {
            return backdrops;
        }
    } catch (e) {
        console.warn('build.env.HIDE_UNOFFICIAL_COSTUMES may not be defined.', e);
        return backdrops;
    }

    return backdrops.filter(backdrop => !backdrop.unofficialCostume);
}

const exportingBackdrops = getExportingBackdrops();

export default exportingBackdrops;
