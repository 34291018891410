import React from 'react';
import classNames from 'classnames';
import Modal from '../../containers/modal.jsx';
import ClassContainer from './class-container/class-container.jsx';
import style from './tm-training-modal.css';
import TrainingResult from './training-result/training-result.jsx';
import isScratchDesktop from '../../lib/isScratchDesktop.js';
import openNewProjectIcon from './open-new-project-icon.svg';
import AddClassButtonIcon from './add-class-button.jsx';

class TmTrainingModalComponent extends React.Component {
    constructor (props) {
        super(props);
        this.resultContainerRef = React.createRef();
        this.tmModalContainerRef = React.createRef();
    }

    render () {
        const {
            onOpenCameraViewOfClass,
            onCloseCameraViewOfClass,
            tmTrainingInstance,
            onCloseTmTrainingModal,
            onDeviceChange,
            onApplyModel,
            onSaveSample,
            isCameraViewOpened,
            classIds,
            onAddNewClass,
            onDeleteSample,
            onClassMenuToggle,
            isClassMenuOpened,
            clearClass,
            deleteClass,
            canDeleteAnyClass,
            isProjectMenuOpen,
            onOpenProjectMenu,
            onCloseProjectMenu,
            onOpenNewProject,
            uploadedSampleLink,
            onAddNewSample,
            isHaveNewSample,
            onSaveProject,
            onLoadProject,
        } = this.props;
        const CONTENT_TITLE = '티처블머신 모델 학습';

        return (<Modal
            fullScreen
            contentLabel={CONTENT_TITLE}
            onRequestClose={onCloseTmTrainingModal}
        >
            <div className={style.container} ref={this.tmModalContainerRef}>
                <div
                    className={classNames(style.menuButton, isProjectMenuOpen && style.active)}
                    onClick={isProjectMenuOpen ? onCloseProjectMenu : onOpenProjectMenu}
                >
                    <div className={style.hamburgerLines}>
                        <span className={style.line} />
                        <span className={style.line} />
                        <span className={style.line} />
                    </div>
                    메뉴
                </div>
                {isProjectMenuOpen && (
                    <div className={style.projectMenu}>
                        <button
                            className={style.projectMenuItem}
                            onClick={onOpenNewProject}
                        >
                            <img
                                className={style.openNewProjectIcon}
                                src={openNewProjectIcon}
                            />
                            새 프로젝트
                        </button>
                        {isScratchDesktop() && (
                            <>
                                <button
                                    className={style.projectMenuItem}
                                    onClick={onSaveProject}
                                >
                                    프로젝트 저장하기
                                </button>
                                <label className={classNames(style.projectMenuItem, style.projectMenuItemLoad)}>
                                    <input
                                        type='file'
                                        style={{display: 'none'}}
                                        accept='.zip'
                                        onChange={onLoadProject}
                                    />
                                    프로젝트 불러오기
                                </label>
                            </>
                        )}
                    </div>
                )}
                <div className={style.columnContainer}>
                    <div className={style.classContainerColumn}>
                        <div className={style.classContainerWrapper}>
                            {classIds.map(id => (<ClassContainer
                                key={id}
                                classId={id}
                                tmTrainingInstance={tmTrainingInstance}
                                video={tmTrainingInstance.video}
                                isCameraViewOpened={isCameraViewOpened(id)}
                                onDeviceChange={onDeviceChange}
                                onOpenCameraViewOfClass={onOpenCameraViewOfClass}
                                onCloseCameraViewOfClass={onCloseCameraViewOfClass}
                                onDeleteSample={index => onDeleteSample(id, index)}
                                onClassMenuToggle={() => onClassMenuToggle(id)}
                                isClassMenuOpened={isClassMenuOpened(id)}
                                clear={() => clearClass(id)}
                                delete={() => deleteClass(id)}
                                canDelete={canDeleteAnyClass}
                                onAddNewSample={onAddNewSample}
                                resultContainerRef={this.resultContainerRef}
                                tmModalContainerRef={this.tmModalContainerRef}
                            />)
                            )}
                            <button
                                className={style.addClassButton}
                                onClick={onAddNewClass}
                            >
                                <AddClassButtonIcon />
                                {'클래스 추가'}
                            </button>
                        </div>
                    </div>
                    <div className={style.trainingResultColumn}>
                        <TrainingResult
                            video={tmTrainingInstance.video}
                            onDeviceChange={onDeviceChange}
                            onApplyModel={onApplyModel}
                            onSaveSample={onSaveSample}
                            tmTrainingInstance={tmTrainingInstance}
                            uploadedSampleLink={uploadedSampleLink}
                            isHaveNewSample={isHaveNewSample}
                            resultContainerRef={this.resultContainerRef}
                        />
                    </div>
                </div>
            </div>
        </Modal>);
    };
}
export default TmTrainingModalComponent;