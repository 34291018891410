import React from 'react';
import styles from './tutorial-modal.css';
import classNames from 'classnames';

const TutorialFailModalComponent = ({ 
    onClickTutorialRestartButton,
    onClickTutorialHelpButton,
}) => {
    return (
        <div className={classNames(styles.dialogWrapper)}>
            <img src={require("../../assets/tutorial-dialog/fail.svg")} />
            <button
                onClick={onClickTutorialRestartButton}
                className={classNames(styles.buttonCommon, styles.failRetryButton)} 
            />
        </div>
    );
}

export default TutorialFailModalComponent;