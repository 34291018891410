import React from 'react';
import {FormattedMessage} from 'react-intl';

import musicIconURL from './music/music.png';
import musicInsetIconURL from './music/music-small.svg';

import penIconURL from './pen/pen.png';
import penInsetIconURL from './pen/pen-small.svg';

import videoSensingIconURL from './videoSensing/video-sensing.png';
import videoSensingInsetIconURL from './videoSensing/video-sensing-small.svg';

import text2speechIconURL from './text2speech/text2speech.png';
import text2speechInsetIconURL from './text2speech/text2speech-small.svg';

import translateIconURL from './translate/translate.png';
import translateInsetIconURL from './translate/translate-small.png';

import makeymakeyIconURL from './makeymakey/makeymakey.png';
import makeymakeyInsetIconURL from './makeymakey/makeymakey-small.svg';

import microbitIconURL from './microbit/microbit.png';
import microbitInsetIconURL from './microbit/microbit-small.svg';
import microbitConnectionIconURL from './microbit/microbit-illustration.svg';
import microbitConnectionSmallIconURL from './microbit/microbit-small.svg';

import ev3IconURL from './ev3/ev3.png';
import ev3InsetIconURL from './ev3/ev3-small.svg';
import ev3ConnectionIconURL from './ev3/ev3-hub-illustration.svg';
import ev3ConnectionSmallIconURL from './ev3/ev3-small.svg';

import wedo2IconURL from './wedo2/wedo.png'; // TODO: Rename file names to match variable/prop names?
import wedo2InsetIconURL from './wedo2/wedo-small.svg';
import wedo2ConnectionIconURL from './wedo2/wedo-illustration.svg';
import wedo2ConnectionSmallIconURL from './wedo2/wedo-small.svg';
import wedo2ConnectionTipIconURL from './wedo2/wedo-button-illustration.svg';

import boostIconURL from './boost/boost.png';
import boostInsetIconURL from './boost/boost-small.svg';
import boostConnectionIconURL from './boost/boost-illustration.svg';
import boostConnectionSmallIconURL from './boost/boost-small.svg';
import boostConnectionTipIconURL from './boost/boost-button-illustration.svg';

import gdxforIconURL from './gdxfor/gdxfor.png';
import gdxforInsetIconURL from './gdxfor/gdxfor-small.svg';
import gdxforConnectionIconURL from './gdxfor/gdxfor-illustration.svg';
import gdxforConnectionSmallIconURL from './gdxfor/gdxfor-small.svg';

import teachableMachineIconURL from './teachableMachine/teachable-machine.svg';
import teachableMachineInsetIconURL from './teachableMachine/teachable-machine-small.svg';

import markCodingIconURL from './markcoding/mark_coding.png';
import markCodingInsetIconURL from './markcoding/mark_coding_small.svg';

import nemoIconURL from './nemo/nemo.png';
import nemoInsetIconURL from './nemo/nemo-small.svg';

import malrangIconURL from './malrang/malrang.png';
import malrangInsetIconURL from './malrang/malrang-small.png';

import skykickIconURL from './byrobotDrone/byrobot-skykick.png';
import codingRiderIconURL from './byrobotDrone/byrobot-coding-rider.png';
import aluxLogoIconURL from './byrobotDrone/icon-alux-logo.png';
import {SKYKICK_EXTENSION_ID, CODING_RIDER_EXTENSION_ID} from '../../isByrobotDroneEx';

import codingDroneIconURL from './codingDrone/coding-drone.png';

import recognitionIconURL from './recognition/recognition.png';
import recognitionInsetIconURL from './recognition/recognition-small.png';

import QRcodeIconURL from './qrcode/qrcode.png';
import QRcodeInsetIconURL from './qrcode/qrcode-small.png';

import ArucoIconURL from './aruco/aruco.png';
import ArucoInsetIconURL from './aruco/aruco-small.png';

import MecanumWheelIconUrl from './mecanumwheel/mecanumwheel.png';
import MecanumWheelInsetIconUrl from './mecanumwheel/mecanumwheel_icon.png';

import generativeAIIconUrl from './generativeAi/ChatGPT2.png';
import generativeAIInsetIconUrl from './generativeAi/ChatGPT_icon2.png';

import technicIconURL from './technic/technic.png';
import technicInsetIconUrl from './technic/technic-small.png';

const officialExtensionCards = [
    {
        name: (
            <FormattedMessage
                defaultMessage="Music"
                description="Name for the 'Music' extension"
                id="gui.extension.music.name"
            />
        ),
        extensionId: 'music',
        iconURL: musicIconURL,
        insetIconURL: musicInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Play instruments and drums."
                description="Description for the 'Music' extension"
                id="gui.extension.music.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pen"
                description="Name for the 'Pen' extension"
                id="gui.extension.pen.name"
            />
        ),
        extensionId: 'pen',
        iconURL: penIconURL,
        insetIconURL: penInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Draw with your sprites."
                description="Description for the 'Pen' extension"
                id="gui.extension.pen.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Video Sensing"
                description="Name for the 'Video Sensing' extension"
                id="gui.extension.videosensing.name"
            />
        ),
        extensionId: 'videoSensing',
        iconURL: videoSensingIconURL,
        insetIconURL: videoSensingInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense motion with the camera."
                description="Description for the 'Video Sensing' extension"
                id="gui.extension.videosensing.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Text to Speech"
                description="Name for the Text to Speech extension"
                id="gui.extension.text2speech.name"
            />
        ),
        extensionId: 'text2speech',
        collaborator: 'Amazon Web Services',
        iconURL: text2speechIconURL,
        insetIconURL: text2speechInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make your projects talk."
                description="Description for the Text to speech extension"
                id="gui.extension.text2speech.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Translate"
                description="Name for the Translate extension"
                id="gui.extension.translate.name"
            />
        ),
        extensionId: 'translate',
        collaborator: 'Google',
        iconURL: translateIconURL,
        insetIconURL: translateInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Translate text into many languages."
                description="Description for the Translate extension"
                id="gui.extension.translate.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: 'Makey Makey',
        extensionId: 'makeymakey',
        collaborator: 'JoyLabz',
        iconURL: makeymakeyIconURL,
        insetIconURL: makeymakeyInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make anything into a key."
                description="Description for the 'Makey Makey' extension"
                id="gui.extension.makeymakey.description"
            />
        ),
        featured: true
    },
    {
        name: 'micro:bit',
        extensionId: 'microbit',
        collaborator: 'micro:bit',
        iconURL: microbitIconURL,
        insetIconURL: microbitInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Connect your projects with the world."
                description="Description for the 'micro:bit' extension"
                id="gui.extension.microbit.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: microbitConnectionIconURL,
        connectionSmallIconURL: microbitConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their micro:bit."
                id="gui.extension.microbit.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/microbit'
    },
    {
        name: 'LEGO MINDSTORMS EV3',
        extensionId: 'ev3',
        collaborator: 'LEGO',
        iconURL: ev3IconURL,
        insetIconURL: ev3InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build interactive robots and more."
                description="Description for the 'LEGO MINDSTORMS EV3' extension"
                id="gui.extension.ev3.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: ev3ConnectionIconURL,
        connectionSmallIconURL: ev3ConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting. Make sure the pin on your EV3 is set to 1234."
                description="Message to help people connect to their EV3. Must note the PIN should be 1234."
                id="gui.extension.ev3.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/ev3'
    },
    {
        name: 'LEGO BOOST',
        extensionId: 'boost',
        collaborator: 'LEGO',
        iconURL: boostIconURL,
        insetIconURL: boostInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Bring robotic creations to life."
                description="Description for the 'LEGO BOOST' extension"
                id="gui.extension.boost.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: boostConnectionIconURL,
        connectionSmallIconURL: boostConnectionSmallIconURL,
        connectionTipIconURL: boostConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their BOOST."
                id="gui.extension.boost.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/boost'
    },
    {
        name: 'LEGO Education WeDo 2.0',
        extensionId: 'wedo2',
        collaborator: 'LEGO',
        iconURL: wedo2IconURL,
        insetIconURL: wedo2InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build with motors and sensors."
                description="Description for the 'LEGO WeDo 2.0' extension"
                id="gui.extension.wedo2.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: wedo2ConnectionIconURL,
        connectionSmallIconURL: wedo2ConnectionSmallIconURL,
        connectionTipIconURL: wedo2ConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their WeDo."
                id="gui.extension.wedo2.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/wedo'
    },
    {
        name: 'Go Direct Force & Acceleration',
        extensionId: 'gdxfor',
        collaborator: 'Vernier',
        iconURL: gdxforIconURL,
        insetIconURL: gdxforInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense push, pull, motion, and spin."
                description="Description for the Vernier Go Direct Force and Acceleration sensor extension"
                id="gui.extension.gdxfor.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: gdxforConnectionIconURL,
        connectionSmallIconURL: gdxforConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their force and acceleration sensor."
                id="gui.extension.gdxfor.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/vernier'
    }
];
const customExtensionCards = [
    {
        name: (
            <FormattedMessage
                defaultMessage="Teachable machine"
                id="gui.extension.teachableMachine.title"
            />
        ),
        extensionId: 'teachableMachine',
        collaborator: '에이럭스',
        iconURL: teachableMachineIconURL,
        insetIconURL: teachableMachineInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="With Machine Learning, you can build artificial neural networks without programming."
                description="Extensions that make use of teachable machine"
                id="gui.extension.teachableMachine.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: false,
        useAutoScan: false
    },
    // TODO: 코딩버스 링크 기본 베이스 구현을 위한 코드. desc 등 수정필요. 참고: https://pocl.atlassian.net/browse/POCL-3555
    {
        name: (
            <FormattedMessage
                defaultMessage="MarkCoding"
                id="gui.extension.markCoding.title"
            />
        ),
        extensionId: 'markCoding',
        collaborator: '에이럭스',
        iconURL: markCodingIconURL,
        insetIconURL: markCodingInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="With block coding, create your own virtual world in Minecraft."
                description="Extensions that make use of markCoding"
                id="gui.extension.markCoding.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectingMessage: (
            <span>마크링크와 연결중입니다</span>
        ),
        connectionIconURL: markCodingInsetIconURL,
        connectionTipIconURL: markCodingInsetIconURL,
        helpLink: 'https://sites.google.com/pocl.io/pro-information/%ED%99%88/%EB%A7%88%ED%81%AC%EB%A7%81%ED%81%AC'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="NEMO"
                id="gui.extension.nemo.title"
            />
        ),
        extensionId: 'nemo',
        collaborator: (
            <FormattedMessage
                defaultMessage="PROBO EDU"
                id="gui.extension.nemo.collaborator"
            />
        ),
        iconURL: nemoIconURL,
        insetIconURL: nemoInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="With NEMO, you can create programs that respond to sensors."
                description="Extensions that make use of NEMO"
                id="gui.extension.nemo.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        usbRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        helpLink: 'https://sites.google.com/pocl.io/semobae/%EC%9D%B4%EC%9A%A9-%EA%B0%80%EC%9D%B4%EB%93%9C/%EC%BD%94%EB%94%A9%ED%88%B4/%EB%84%A4%EB%AA%A8%EC%BD%94%EB%94%A9-%EC%97%B0%EA%B2%B0%EB%B0%A9%EB%B2%95'
    },
    {
        // TODO: Fix to correct designs and icons
        name: (
            <FormattedMessage
                defaultMessage="Recognition"
                description="title of recognition system"
                id="gui.extension.recognition.title"
            />
        ),
        extensionId: 'recognition',
        collaborator: '에이럭스',
        iconURL: recognitionIconURL,
        insetIconURL: recognitionInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="With implemented artificial intellegence model, use blocks related video or audio."
                description="description of recognition system"
                id="gui.extension.recognition.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        usbRequired: false,
        internetConnectionRequired: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Malrang"
                description="title of malrang system"
                id="gui.extension.malrang.title"
            />
        ),
        extensionId: 'malrang',
        collaborator: '프로보에듀',
        iconURL: malrangIconURL,
        insetIconURL: malrangInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Fun treasure hunt with scratch! Let's go on a malrang malrang coding trip."
                description="description of malrang system"
                id="gui.extension.malrang.description"
            />
        ),
        featured: true,
        usbRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        helpLink: 'https://sites.google.com/pocl.io/semobae/%EC%9D%B4%EC%9A%A9-%EA%B0%80%EC%9D%B4%EB%93%9C/%EC%BD%94%EB%94%A9%ED%88%B4/%EB%A7%90%EB%9E%91%EC%BD%94%EB%94%A9-%EC%97%B0%EA%B2%B0%EB%B0%A9%EB%B2%95'
    },
    {
        name: (<FormattedMessage
            defaultMessage="SKYKICK"
            description="SKYKICK"
            id="gui.extension.skykick.title"
        />),
        extensionId: SKYKICK_EXTENSION_ID,
        collaborator: 'FIDA',
        iconURL: skykickIconURL,
        insetIconURL: skykickIconURL,
        description: 'Coding for SKYKICK EVOLUTION',
        featured: true,
        usbRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        // connectionIconURL: skykickIconURL,
        // connectionSmallIconURL: skykickIconURL,
        // connectionTipIconURL: skykickIconURL,
        // connectingMessage: (
        //     <span>드론과 연결중입니다</span>
        // ),
        helpLink: ''
    },
    {
        name: (<FormattedMessage
            defaultMessage="QRcode"
            description="Using Camera, read QRcode"
            id="gui.extension.qrcode.title"
        />),
        extensionId: 'qrcode',
        collaborator: '에이럭스',
        iconURL: QRcodeIconURL,
        insetIconURL: QRcodeInsetIconURL,
        description: (<FormattedMessage
            defaultMessage="Using Camera, read QRcode"
            description="Using Camera, read QRcode"
            id="gui.extension.qrcode.description"
        />),
        featured: true,
        // usbRequired: true,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: true,
        // connectionIconURL: QRcodeIconURL,
        // connectionSmallIconURL: QRcodeIconURL,
        // connectionTipIconURL: QRcodeIconURL,
        // connectingMessage: (
        //     <span></span>
        // ),
        helpLink: ''
    },
    {
        name: (<FormattedMessage
            defaultMessage="ArUco Marker"
            description="Using Camera, read Aruco Marker"
            id="gui.extension.aruco.title"
        />),
        extensionId: 'aruco',
        collaborator: '에이럭스',
        iconURL: ArucoIconURL,
        insetIconURL: ArucoInsetIconURL,
        description: (<FormattedMessage
            defaultMessage="Using Camera, read Aruco Marker"
            description="Using Camera, read Aruco Marker"
            id="gui.extension.aruco.description"
        />),
        featured: true,
        // usbRequired: true,
        internetConnectionRequired: true,
        // launchPeripheralConnectionFlow: true,
        // useAutoScan: true,
        // connectionIconURL: QRcodeIconURL,
        // connectionSmallIconURL: QRcodeIconURL,
        // connectionTipIconURL: QRcodeIconURL,
        // connectingMessage: (
        //     <span></span>
        // ),
        helpLink: ''
    },
    {
        name: (<FormattedMessage
            defaultMessage="MecanumWheel"
            description="Using MecanumWheel"
            id="gui.extension.mecanumWheel.title"
        />),
        extensionId: 'mecanumWheel',
        collaborator: (<FormattedMessage
            defaultMessage="PROBO EDU"
            description="PROBO EDU"
            id="gui.extension.mecanumWheel.collaborator"
        />),
        iconURL: MecanumWheelIconUrl,
        insetIconURL: MecanumWheelInsetIconUrl,
        description: (<FormattedMessage
            defaultMessage="Using MecanumWheel"
            description="Using MecanumWheel"
            id="gui.extension.mecanumWheel.description"
        />),
        featured: true,
        usbRequired: true,
        bluetoothRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        helpLink: ''
    },
    {
        name: (<FormattedMessage
            defaultMessage="generative ai (beta)"
            description="generative ai"
            id="gui.extension.generativeAi.name"
        />),
        extensionId: 'generativeAi',
        iconURL: generativeAIIconUrl,
        insetIconURL: generativeAIInsetIconUrl,
        description: (<FormattedMessage
            defaultMessage="generative ai"
            description="generate variaous texts and images using ai"
            id="gui.extension.generativeAi.description"
        />),
        featured: true,
        internetConnectionRequired: true,
        helpLink: ''
    },
    {
        name: (<FormattedMessage
            defaultMessage="technic"
            description="technic"
            id="gui.extension.technic.name"
        />),
        extensionId: 'technic',
        iconURL: technicIconURL,
        insetIconURL: technicInsetIconUrl,
        description: (<FormattedMessage
            defaultMessage="probo technic coding robot"
            description="using probo technic coding robot"
            id="gui.extension.technic.description"
        />),
        collaborator: (<FormattedMessage
            defaultMessage="PROBO EDU"
            description="PROBO EDU"
            id="gui.extension.technic.collaborator"
        />),
        featured: true,
        internetConnectionRequired: true,
        usbRequired: true,
        useAutoScan: true,
        launchPeripheralConnectionFlow: true,
        bluetoothRequired: true
    },
    {
        name: (<FormattedMessage
            defaultMessage="coding rider(ALC-95)"
            description="coding rider(ALC-95)"
            id="gui.extension.codingRider.title"
        />),
        extensionId: CODING_RIDER_EXTENSION_ID,
        collaborator: 'ALUX',
        iconURL: codingRiderIconURL,
        insetIconURL: aluxLogoIconURL,
        description: (<FormattedMessage
            defaultMessage="Fly a drone through coding."
            description="Fly a drone through coding."
            id="gui.extension.codingRider.description"
        />),
        featured: true,
        usbRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        // connectionIconURL: codingRiderIconURL,
        // connectionSmallIconURL: codingRiderIconURL,
        // connectionTipIconURL: codingRiderIconURL,
        // connectingMessage: (
        //     <span>드론과 연결중입니다</span>
        // ),
        helpLink: ''
    },
    {
        name: (<FormattedMessage
            defaultMessage="coding drone(BRC-105)"
            description="coding drone"
            id="gui.extension.codingDrone.title"
        />),
        extensionId: "codingDrone",
        collaborator: 'ALUX',
        iconURL: codingDroneIconURL,
        insetIconURL: aluxLogoIconURL,
        description: (<FormattedMessage
            defaultMessage="Fly using the drone's various sensors"
            description="Fly using the drone's various sensors"
            id="gui.extension.codingDrone.description"
        />),
        featured: true,
        usbRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
    },
];


function getExtensionCards() {
    const defaultExtensionCards = officialExtensionCards.concat(customExtensionCards);
    let extensionCards = defaultExtensionCards;

    try {
        let visibleCustomExtensionCards = customExtensionCards;

        if (build.env.USE_EXTENSION_WHITELIST) {
            visibleCustomExtensionCards = [];
            const extensionWhitelist = build.env.EXTENSION_WHITELIST;

            for (const extensionId of extensionWhitelist) {
                const extensionCard = customExtensionCards.find(card => card.extensionId === extensionId);
                if (extensionCard && !visibleCustomExtensionCards.includes(extensionCard)) {
                    visibleCustomExtensionCards.push(extensionCard);
                } else {
                    console.warn(`Extension whitelist includes unknown or duplicated extension: ${extensionId}.`);
                }
            }
        } else if (build.env.EXTENSION_WHITELIST.length > 0) {
            console.warn('USE_EXTENSION_WHITELIST is not enabled, but EXTENSION_WHITELIST is set.');
        }

        if (build.env.HOISTING_CUSTOM_EXTENSIONS) {
            extensionCards = visibleCustomExtensionCards.concat(officialExtensionCards);
        } else {
            extensionCards = officialExtensionCards.concat(visibleCustomExtensionCards);
        }
    } catch (e) {
        console.warn('build.env may not be defined.', e);
    }
    return extensionCards;
}

export default getExtensionCards();
