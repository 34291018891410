export const SEMOBAE_USER_TYPE = {
    TEACHER: 1,
    STUDENT: 2,
    SCHOOL_MANAGER: 3,
    CONTENT_CREATOR: 4,
    SYSTEM_OPERATOR: 5,
};

export function writeStudyFile (saveProjectSb3, fileName) {
    if (!window.semobaeAPI) {
        return Promise.resolve();
    }
    return saveProjectSb3().then(blob => blob.arrayBuffer())
        .then(arrayBuffer => {
            console.log('write study file : ', arrayBuffer);
            window.semobaeAPI.writeStudyFile(fileName, arrayBuffer);
        });
}

export function getStudyFile (fileName, codingTemplateUrl) {
    if (!window.semobaeAPI) {
        return new Promise(resolve => resolve);
    }

    return new Promise(resolve => {
        window.semobaeAPI.receiveOnce('studyFile', (event, arrayBuffer) => {
            console.log('study file : ', arrayBuffer);
            if (arrayBuffer && arrayBuffer.byteLength > 1000) {
                const file = new Blob([arrayBuffer]);
                resolve(file);
            } else {
                resolve(null);
            }
        });
        window.semobaeAPI.readStudyFile(fileName);
    });
}

export function getFile (dataURL) {
    if (!window.semobaeAPI || !dataURL) {
        console.log('failed to read file:', dataURL);
        return Promise.resolve();
    }
    return new Promise(resolve => {
        window.semobaeAPI.receiveOnce('loadFile', (event, arrayBuffer) => {
            const file = new Blob([arrayBuffer]);
            resolve(file);
        });
        window.semobaeAPI.send('loadFile', dataURL);
    });
}

export function getCodingTemplateFile (url) {
    if (!window.semobaeAPI || !url) {
        return Promise.resolve();
    }
    return fetchFile(url);
}

function fetchFile (url) {
    return fetch(url).then(response => response.blob());
}

export function createHistoryFile (saveProjectSb3) {
    if (!window.semobaeAPI) {
        return Promise.resolve();
    }
    return saveProjectSb3().then(blob => blob.arrayBuffer())
        .then(arraybuffer => {
            window.semobaeAPI.send('createHistory', arraybuffer);
        });
}

export function uploadExtraFile (fileData, url) {
    if (!window.semobaeAPI) {
        return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
        window.semobaeAPI.receiveOnce('fileURL', (event, result) => {
            if (!result) {
                return reject(result);
            }

            resolve(result);
        });
        window.semobaeAPI.send('saveFile', 'file', JSON.stringify(fileData), url);
    })
}

export function sendTutorialOutcomes(isSuccess, semoclassParams) {
    if (!window.semobaeAPI) {
        return Promise.reject();
    }
    return new Promise(resolve => {
        window.semobaeAPI.receiveOnce('tutorialOutcomes', (event, result) => {
            resolve(result);
        });
        window.semobaeAPI.send('tutorialOutcomes', {
            ...semoclassParams,
            isSuccess
        });
    });
}

export function requestTutorialSb3File({ user, semoclass }) {
    if (!window.semobaeAPI) {
        return Promise.reject();
    }
    window.semobaeAPI.send('requestCodingTemplateURL', { user, semoclass });
}

export function showMalrangTutorialHelpDialog() {
    if (!window.semobaeAPI) {
        return;
    }
    // TODO: https://pocl.atlassian.net/browse/POCL-12380
    // TODO: https://pocl.atlassian.net/browse/POCL-12381
    window.semobaeAPI.send('showMalrangTutorialHelpDialog');
}

export const downloadExtraFile = getFile;
