import React from 'react';
import bindAll from 'lodash.bindall';
import { SPRITE_LIST_TYPE } from '../lib/constant/malrangTutorial.js';
import MalrangSpriteItemComponent from '../components/malrang-tutorial-modal/sprite-selector/malrang-sprite-item.jsx';
import styles from '../components/malrang-tutorial-modal/sprite-selector/malrang-sprite-list.css';
import classNames from 'classnames';

class MalrangSpriteItem extends React.PureComponent {
    constructor (props) {
        super(props);
        const initialSpriteOnClick = () => this.props.setInitialSprite(this.props.sprite.id);
        const hideSpriteOnClick = () => this.props.setSpriteVisibleOnTargetPane(this.props.sprite.id, this.props.isSpriteHidden);

        this.state = {
            clicked: this.props.sprite.id === this.props.initialSpriteId && this.props.type === SPRITE_LIST_TYPE.SET_INITIAL,
            onClick: this.props.type === SPRITE_LIST_TYPE.SET_INITIAL ? initialSpriteOnClick : hideSpriteOnClick,
            spriteHiddenStyle: null
        };

        bindAll(this, [
            'setSpriteHiddenStyle',
            'setClicked',
            'setOnClickSpriteItem'
        ]);
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.isSpriteHidden !== this.props.isSpriteHidden) ||
            (prevProps.isSpriteHideFromStage !== this.props.isSpriteHideFromStage)
        ) {
            this.setClicked();
            this.setOnClickSpriteItem();
            this.setSpriteHiddenStyle();
        }
        
        if (prevProps.initialSpriteId !== this.props.initialSpriteId) {
            this.setClicked();
        }
    }

    setSpriteHiddenStyle () {
        if (this.props.type !== SPRITE_LIST_TYPE.HIDE) {
            return;
        }

        if (!this.props.isSpriteHidden) {
            this.setState({
                spriteHiddenStyle: null
            });
            return;
        }

        if (this.props.isSpriteHideFromStage) {
            this.setState({
                spriteHiddenStyle: classNames(styles.spriteInfo, styles.hiddenFromStageSpriteInfo)
            });
        } else {
            this.setState({
                spriteHiddenStyle: classNames(styles.spriteInfo, styles.hiddenSpriteInfo)
            });
        }
    }

    setClicked() {
        if(this.props.type === SPRITE_LIST_TYPE.SET_INITIAL) {
            this.setState({
                clicked: this.props.sprite.id === this.props.initialSpriteId
            });
        }
        if (this.props.type === SPRITE_LIST_TYPE.HIDE) {
            this.setState({
                clicked: this.props.isSpriteHidden
            });
        }
    }

    setOnClickSpriteItem () {
        switch (this.props.type) {
            case SPRITE_LIST_TYPE.HIDE:
                if (!this.props.isSpriteHidden) {
                    this.setState({
                        onClick: () => this.props.setSpriteVisibleOnTargetPane(this.props.sprite.id, this.props.isSpriteHidden)
                    });
                    break;
                }
                if (this.props.isSpriteHideFromStage) {
                    this.setState({
                        onClick: () => {
                            this.props.setSpriteVisibleOnTargetPane(this.props.sprite.id, this.props.isSpriteHidden);
                            this.props.onClickHideFromStageCheckbox(this.props.sprite.id);
                        }
                    });
                } else {
                    this.setState({
                        onClick: () => this.props.onClickHideFromStageCheckbox(this.props.sprite.id)
                    });
                }
                break;
            case SPRITE_LIST_TYPE.SET_INITIAL:
                this.setState({
                    onClick: () => this.props.setInitialSprite(this.props.sprite.id)
                });
                break;
            default:
                break;
        }
    }

    render () {
        const {
            sprite,
        } = this.props;

        return (
            <MalrangSpriteItemComponent
                sprite={sprite}
                clicked={this.state.clicked}
                onClick={this.state.onClick}
                spriteHiddenStyle={this.state.spriteHiddenStyle}
            />
        );
    }
}

export default MalrangSpriteItem;