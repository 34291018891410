const getCheckedblocks = (visibleBlocks, checkedHideBlocks) => {
    let filterdBlock = {};

    Object.keys(visibleBlocks).forEach((category) => {
        filterdBlock[category] = [];
        visibleBlocks[category].forEach((opcode) => {
            if(!checkedHideBlocks.includes(opcode)) {
                filterdBlock[category].push(opcode);
            }
        });
    });

    if (filterdBlock['event'] && !filterdBlock['event'].includes('event_broadcast')) {
        filterdBlock['event'] = filterdBlock['event'].filter(block => block !== 'event_broadcast_menu');
    } 

    return filterdBlock;
}

const createCheckboxElement = () => {
    const checkboxSvg = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    checkboxSvg.style.transform = 'scale(1.2) translate(-40px, 5px)';
    checkboxSvg.setAttribute('class', 'malrangcheckbox');
    makeCheckboxFrame(checkboxSvg);
    makeCheckBoxBackground(checkboxSvg);
    makeCheck(checkboxSvg);

    return checkboxSvg;
};

const makeCheckboxFrame = (checkboxSvg) => {
    const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    rect.setAttribute('class', 'malrangcheckboxframe');
    rect.setAttribute('width', '25');
    rect.setAttribute('height', '25');
    rect.setAttribute('stroke', '#999999');
    rect.setAttribute('stroke-width', '2');
    rect.setAttribute('fill', '#FFFFFF');
    rect.setAttribute('rx', '6');
    rect.setAttribute('ry', '6');

    checkboxSvg.appendChild(rect);
};

const makeCheckBoxBackground = (checkboxSvg) => {
    const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    rect.setAttribute('class', 'malrangcheckboxbackground');
    rect.setAttribute('width', '25');
    rect.setAttribute('height', '25');
    rect.setAttribute('fill', '#35DD1A');
    rect.setAttribute('rx', '6');
    rect.setAttribute('ry', '6');

    checkboxSvg.appendChild(rect);
};

const makeCheck = (checkboxSvg) => {
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.style.transform = 'translate(3px, 5px)';
    path.setAttribute('class', 'malrangcheckboxcheck');
    path.setAttribute('d', 'M3 6L6 12L15 2');
    path.setAttribute('stroke', 'white');
    path.setAttribute('fill', 'none');
    path.setAttribute('stroke-width', '4');
    path.setAttribute('stroke-linecap', 'round');

    checkboxSvg.appendChild(path);
}

const changeShowBlockCheckboxSvg = (checkboxSvg) => {
    checkboxSvg.classList.add('malrangchecked');
    checkboxSvg.querySelector('.malrangcheckboxbackground').style.display = 'none';
    checkboxSvg.querySelector('.malrangcheckboxcheck').style.display = 'none';
    checkboxSvg.querySelector('.malrangcheckboxframe').style.display = 'block';


    return checkboxSvg;
}

const changeHideBlockCheckboxSvg = (checkboxSvg) => {
    checkboxSvg.classList.remove('malrangchecked');
    checkboxSvg.querySelector('.malrangcheckboxbackground').style.display = 'block';
    checkboxSvg.querySelector('.malrangcheckboxcheck').style.display = 'block';
    checkboxSvg.querySelector('.malrangcheckboxframe').style.display = 'none';

    return checkboxSvg;
}

const handleCheckedHideBlocks = (opcode, runtime) => {
    const checkedHideBlocks = runtime.checkedHideBlocks;

    if (checkedHideBlocks.includes(opcode)) {
        runtime.checkedHideBlocks = checkedHideBlocks.filter((item) => item !== opcode);
    } else {
        runtime.checkedHideBlocks = [...checkedHideBlocks, opcode];
    }

    runtime.visibleBlocks = getCheckedblocks(runtime.allVisibleBlocks, runtime.checkedHideBlocks);
}

const handleCheckedVariableBlocks = (opcode, vm) => {
    const runtime = vm.runtime;

    if (runtime.checkedVariableBlocks.includes(opcode)) {
        runtime.checkedVariableBlocks = runtime.checkedVariableBlocks.filter((item) => item !== opcode);
    } else {
        runtime.checkedVariableBlocks = [...runtime.checkedVariableBlocks, opcode];
    }

    vm.workspaceVariableNames = getUnCheckedWorkspaceVariableNames(getWorkspaceVariableNames(runtime), vm.runtime.checkedVariableBlocks);
}

const handleCheckedProcedureBlocks = (opcode, vm) => {
    const runtime = vm.runtime;

    if (runtime.checkedProcedureBlocks.includes(opcode)) {
        runtime.checkedProcedureBlocks = runtime.checkedProcedureBlocks.filter((item) => item !== opcode);
    } else {
        runtime.checkedProcedureBlocks = [...runtime.checkedProcedureBlocks, opcode];
    }

    vm.workspaceProcCodes = getUnCheckedWorkspaceProcedureNames(getWorkspaceProcCodes(runtime), vm.runtime.checkedProcedureBlocks);
}

const makeHideBlockCheckboxes = (vm) => {
    const draggable = document.querySelectorAll('.blocklyFlyout .blocklyDraggable');
    draggable.forEach((element) => {
        makeCheckBoxInBlockElement(element, vm);
    });
}

const removeHideBlockCheckboxes = () => {
    const draggable = document.querySelectorAll('.blocklyFlyout .blocklyDraggable');
    draggable.forEach((element) => {
        removeCheckBoxInBlockElement(element);
    });
}
const getUniqueBlockName = (blockElement, dataType) => {
    if (dataType === 'procedures_call') {
        const argumentElements = blockElement.querySelectorAll('.blocklyText, .blocklyPath:not(.blocklyBlockBackground)');
        const procedureIdWithArguments = Array.from(argumentElements).map((element) => {
            if (element.nodeName === 'text') {
                return element.innerHTML.replace(/&nbsp;/g, ' ');
            } else if (element.nodeName === 'path') {
                if (element.dataset.argumentType === 'round') {
                    return '%s';
                } else if (element.dataset.argumentType === 'boolean') {
                    return '%b';
                }
            }
        }).join(' ');
        
        return procedureIdWithArguments;
    }

    return blockElement.querySelector('text').innerHTML.replace(/&nbsp;/g, ' ');
};

const makeCheckBoxInBlockElement = (blockElement, vm) => {
    const runtime = vm.runtime;
    if (!runtime.openMalrangModal) {
        blockElement.querySelectorAll('.malrangcheckbox').forEach((element) => {
            element.remove();
        });

        return;
    }
    const transformValue = blockElement.getAttribute('transform');
    const matchX = transformValue.match(/translate\((.*?),.*?\)/);
    const matchY = transformValue.match(/translate\(.*?,(.*?)\)/);

    if (!matchX || !matchY) {
        return;
    }

    const oldCheckBox = blockElement.querySelector('.malrangcheckbox');

    if (oldCheckBox) {
        oldCheckBox.remove();
    }

    const currentY = parseInt(matchY[1]);
    blockElement.setAttribute('transform', `translate(${62},${currentY})`);
    const dataType = blockElement.getAttribute('data-type');
    const blockName = getUniqueBlockName(blockElement, dataType);
    
    const checkbox = createCheckboxElement();
    if (dataType === 'data_variable' || dataType === 'data_listcontents') {
        if (runtime.checkedVariableBlocks.includes(blockName)) {
            changeShowBlockCheckboxSvg(checkbox);
        } else {
            changeHideBlockCheckboxSvg(checkbox);
        }

        checkbox.addEventListener('click', () => {
            handleCheckedVariableBlocks(blockName, vm);

            if (checkbox.classList.contains('malrangchecked')) {
                changeHideBlockCheckboxSvg(checkbox);
            } else {
                changeShowBlockCheckboxSvg(checkbox);
            }
        });
    } else if (dataType === 'procedures_call') {
        if (runtime.checkedProcedureBlocks.includes(blockName)) {
            changeShowBlockCheckboxSvg(checkbox);
        } else {
            changeHideBlockCheckboxSvg(checkbox);
        }
        
        checkbox.addEventListener('click', () => {
            handleCheckedProcedureBlocks(blockName, vm);

            if (checkbox.classList.contains('malrangchecked')) {
                changeHideBlockCheckboxSvg(checkbox);
            } else {
                changeShowBlockCheckboxSvg(checkbox);
            }
        });

    } else {
        if (vm.runtime.checkedHideBlocks.includes(dataType)) {
            changeShowBlockCheckboxSvg(checkbox);
        } else {
            changeHideBlockCheckboxSvg(checkbox);
        }

        checkbox.addEventListener('click', () => {
            handleCheckedHideBlocks(dataType, runtime);

            if (checkbox.classList.contains('malrangchecked')) {
                changeHideBlockCheckboxSvg(checkbox);
            } else {
                changeShowBlockCheckboxSvg(checkbox);
            }
        });
    }

    blockElement.appendChild(checkbox);
}

const removeCheckBoxInBlockElement = (blockElement) => {
    const transformValue = blockElement.getAttribute('transform');
    const matchX = transformValue.match(/translate\((.*?),.*?\)/);
    const matchY = transformValue.match(/translate\(.*?,(.*?)\)/);

    if (!matchX || !matchY) {
        return;
    }

    const currentY = parseInt(matchY[1]);
    blockElement.setAttribute('transform', `translate(${12},${currentY})`);

    blockElement.querySelectorAll('.malrangcheckbox').forEach((element) => {
        element.remove();
    });
}

const getUnCheckedWorkspaceVariableNames = (workspaceVariableNames, checkedVariableBlocks) => {
    return workspaceVariableNames.filter((variableName) => {
        return !checkedVariableBlocks.includes(variableName);
    });
};

const getUnCheckedWorkspaceProcedureNames = (workspaceProcedureNames, checkedProcedureBlocks) => {
    return workspaceProcedureNames.filter((procedureName) => {
        return !checkedProcedureBlocks.includes(procedureName);
    });
};

const getAllBlocksInRuntime = (runtimeTargets) => {
    return runtimeTargets.map((target) => {
        return Object.values(target.blocks._blocks)
    }).flat();
}

const getOpcodesInRuntime = (runtime) => {
    const procedureChildBlockIds = runtime.getProcedureChildBlockIds();

    return getAllBlocksInRuntime(runtime.targets)
        .filter((block) => {
            return !procedureChildBlockIds[block.id];
        })
        .map((block) => {
            return block.opcode;
        });
}

const getWorkspaceVariableNames = (runtime) => {
    const workspaceVariableSet = new Set();
    getAllBlocksInRuntime(runtime.targets).filter((block) => {
            return (block.opcode === 'data_variable' || block.opcode === 'data_listcontents');
        })
        .forEach((block) => {
            workspaceVariableSet.add(block.fields.VARIABLE?.value || block.fields.LIST?.value);
        });
    return Array.from(workspaceVariableSet);
}

const getWorkspaceProcCodes = (runtime) => {
    const workspaceProcCodes = new Set();

    getAllBlocksInRuntime(runtime.targets).filter((block) => {
        return block.opcode === 'procedures_call';
    }).forEach((block) => {
        if (block.mutation.proccode) {
            workspaceProcCodes.add(block.mutation.proccode);
        }
    })
    return Array.from(workspaceProcCodes);
}

const ignoredVisibleOpcodes = Object.freeze({
    motion: [
        'motion_goto_menu',
        'motion_glideto_menu',
        'motion_pointtowards_menu'
    ],
    looks: [
        'looks_backdrops'
    ],
    sound: [
        'sound_sounds_menu'
    ],
    event: [],
    control: [
        'control_create_clone_of_menu'
    ],
    sensing: [
        'sensing_touchingobjectmenu',
        'sensing_distancetomenu',
        'sensing_keyoptions',
        'sensing_of_object_menu'
    ],
    operator: [],
    data: [
        'data_variable'
    ],
    procedures: [],
});

const disappearingVariableOpcodes = [
    'data_variable',
    'data_listcontents',
    'data_addtolist',
    'data_deleteoflist',
    'data_deletealloflist',
    'data_insertatlist',
    'data_replaceitemoflist',
    'data_itemoflist',
    'data_itemnumoflist',
    'data_lengthoflist',
    'data_listcontainsitem',
    'data_showlist',
    'data_hidelist'
];

const hasVisibleOpcode = (visibleBlocks, category, visibleVariableOrMyBlockNames, vm) => { 
    if (category === 'data') {
        const hasDataOpcodeInEditingTarget = Object.values(vm.editingTarget.blocks._blocks).some(block => disappearingVariableOpcodes.includes(block.opcode));

        return (
            (!vm.runtime.isMissionMode && visibleVariableOrMyBlockNames.length > 0) ||
            (vm.runtime.isMissionMode && hasDataOpcodeInEditingTarget)
        );
    }

    if (category === 'procedures') {
        const hasProcedureOpcodeInEditingTarget = Object.values(vm.editingTarget.blocks._blocks).map(block => block.opcode).includes('procedures_call');
        
        return (
            (!vm.runtime.isMissionMode && visibleVariableOrMyBlockNames.length > 0) ||
            (vm.runtime.isMissionMode && hasProcedureOpcodeInEditingTarget)
        );
    }

    return visibleBlocks[category]?.some((opcode => !ignoredVisibleOpcodes[category].includes(opcode)))
};

export {
    getCheckedblocks,
    changeShowBlockCheckboxSvg,
    changeHideBlockCheckboxSvg,
    makeCheckBoxInBlockElement,
    makeHideBlockCheckboxes,
    removeHideBlockCheckboxes,
    removeCheckBoxInBlockElement,
    getUnCheckedWorkspaceVariableNames,
    getUnCheckedWorkspaceProcedureNames,
    getAllBlocksInRuntime,
    getOpcodesInRuntime,
    getWorkspaceVariableNames,
    getWorkspaceProcCodes,
    hasVisibleOpcode,
    disappearingVariableOpcodes
};