import React from 'react';
import bindAll from 'lodash.bindall';
import {connect} from 'react-redux';
import TmTrainingModalComponent from '../components/tm-training-modal/tm-training-modal.jsx';
import {closeTMTrainingModal} from '../reducers/modals.js';
import TmTraining from '../../tm-training/dist/tm-training.js';
import {uploadExtraFile, downloadExtraFile} from '../semobae_utils/semobae_utils.js';
import {setVisible, setHidden} from '../reducers/work-loading-state.js';
import downloadBlob from '../lib/download-blob.js';
import JSZip from 'jszip';
class TmTrainingModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'onOpenCameraViewOfClass',
            'onCloseCameraViewOfClass',
            'onDeviceChange',
            'onApplyModel',
            'onSaveSample',
            'isCameraViewOpened',
            'onAddNewClass',
            'onCloseModal',
            'onDeleteSample',
            'onClassMenuToggle',
            'isClassMenuOpened',
            'clearClass',
            'deleteClass',
            'openProjectMenu',
            'closeProjectMenu',
            'openNewProject',
            'openNewProjectWithSample',
            'onAddNewSample',
            'onSaveSampleLocal',
            'onLoadSampleLocal',
            'setNewTmInstance',
            'setNewProjectState'
        ]);

        const modelData = this.props.vm.runtime.teachableMachineModelClassifierDataset;
        this.tmTrainingInstance = new TmTraining();
        this.tmTrainingInstance.init().then(() => {
            if (modelData) {
                this.loadTmTrainingProject(modelData, this.props.vm.runtime.teachableMachineSampleDataURL);
            }
        });
        this.state = {
            classIds: this.tmTrainingInstance.getClassIds(),
            cameraViewOpenedClassId: '',
            deviceId: this.tmTrainingInstance.videoDeviceId,
            isProjectMenuOpen: false,
            menuOpenedClassId: '',
            componentKey: 0,
            isHaveNewSample: false,
            uploadedSample: null,
        };
    }

    componentDidUpdate (prevProps, prevState) {
        if (prevState.componentKey !== this.state.componentKey) {
            this.tmTrainingInstance.start();
        }
    }

    componentWillUnmount () {
        this.tmTrainingInstance.renderTriggers.clear();
        this.tmTrainingInstance.predictionRenderTrigger = null;
    }

    onOpenCameraViewOfClass (id) {
        this.setState({
            cameraViewOpenedClassId: id
        });
    }

    onCloseCameraViewOfClass () {
        this.setState({
            cameraViewOpenedClassId: ''
        });
    }

    async onDeviceChange (deviceId) {
        await this.tmTrainingInstance.setVideoDevice(deviceId);
        this.setState(prev => ({...prev, deviceId}));
    }

    async onSaveSample () {
        this.props.setVisibleWorkLoader();

        const sampleData = this.tmTrainingInstance.getSampleData();
        if (!this.tmTrainingInstance.isOldData) {
            sampleData.version = 1;
        }
        const sampleDataId = crypto.randomUUID();
        const sampleDataURL = await uploadExtraFile(sampleData, this.getSampleDataPath(sampleDataId))
            .catch(console.error);
        this.props.vm.runtime.teachableMachineSampleDataURL = sampleDataURL;
        this.setState({ uploadedSampleLink: sampleDataURL });
        this.setState({ isHaveNewSample: false });
        this.props.setHiddenWorkLoader();
    }

    async onSaveSampleLocal () {
        this.props.setVisibleWorkLoader();
        const sampleData = this.tmTrainingInstance.getSampleData();
        if (!this.tmTrainingInstance.isOldData) {
            sampleData.version = 1;
        }

        const zip = new JSZip();

        zip.file('sampleData.json', JSON.stringify(sampleData));

        await zip.generateAsync({
            type: 'blob',
            mimeType: 'application/zip',
            compression: 'DEFLATE',
            compressionOptions: {
                level: 6
            }
        }).then(content => {
            downloadBlob('sampleData.zip', content);
        });

        this.setState({ isHaveNewSample: false });
        this.props.setHiddenWorkLoader();
        this.closeProjectMenu();
    }

    onLoadSampleLocal (e) {
        this.props.setVisibleWorkLoader();
        this.fileReader = new FileReader();
        this.fileReader.onload = async (e) => {
            try {
                const arrayBuffer = e.target.result;
                const zip = await JSZip.loadAsync(arrayBuffer);
                const content = await zip.file('sampleData.json').async('text');
                await this.openNewProjectWithSample(JSON.parse(content));
            } catch (e) {
                console.error('Failed to load sample data from zip file: ', e);
            } finally {
                this.props.setHiddenWorkLoader();
                this.closeProjectMenu();
            };
        };
        this.fileReader.readAsArrayBuffer(e.target.files[0]);
    }

    async onApplyModel () {
        this.tmTrainingInstance.stop();
        this.props.setVisibleWorkLoader();

        const modelData = this.tmTrainingInstance.getModelData();

        await this.props.vm.extensionManager.setTeachableMachineBlocksWithOfflineModel(modelData);
        this.props.vm.runtime.emitProjectChanged();
        this.props.onCloseTmTrainingModal();
        this.props.setHiddenWorkLoader();
    }

    isCameraViewOpened (id) {
        return this.state.cameraViewOpenedClassId === id;
    }

    onAddNewClass () {
        this.tmTrainingInstance.addNewClass();
        this.setState({classIds: this.tmTrainingInstance.getClassIds()});
    }

    onCloseModal () {
        this.tmTrainingInstance.stop();
        this.props.onCloseTmTrainingModal();
    }

    onDeleteSample (id, index) {
        this.tmTrainingInstance.deleteSample(id, index);
    }

    onClassMenuToggle (id) {
        if (this.state.menuOpenedClassId === id) {
            this.setState({menuOpenedClassId: ''});
            return;
        }
        this.setState({menuOpenedClassId: id});
    }

    isClassMenuOpened (id) {
        return this.state.menuOpenedClassId === id;
    }

    clearClass (id) {
        this.tmTrainingInstance.clearClass(id);
    }

    deleteClass (classId) {
        this.tmTrainingInstance.deleteClass(classId);
        this.setState({classIds: this.tmTrainingInstance.getClassIds()});
    }

    openProjectMenu () {
        this.setState({isProjectMenuOpen: true});
    }

    closeProjectMenu () {
        this.setState({isProjectMenuOpen: false});
    }

    async openNewProject () {
        await this.setNewTmInstance();
        this.setNewProjectState();
    }

    async openNewProjectWithSample (sampleData) {
        await this.setNewTmInstance();
        await this.tmTrainingInstance.loadSampleData(sampleData);
        this.setNewProjectState();
    }

    async setNewTmInstance () {
        if (!this.tmTrainingInstance) {
            return;
        }
        this.tmTrainingInstance.stop();
        this.tmTrainingInstance = new TmTraining();
        await this.tmTrainingInstance.init();
    }

    setNewProjectState () {
        this.setState({
            classIds: this.tmTrainingInstance.getClassIds(),
            cameraViewOpenedClassId: '',
            deviceId: this.tmTrainingInstance.videoDeviceId,
            isProjectMenuOpen: false,
            menuOpenedClassId: '',
            componentKey: this.state.componentKey + 1,
        });
    }

    async loadTmTrainingProject (modelData, sampleDataURL) {
        this.props.setVisibleWorkLoader();
        try {
            const sampleDataJSON = await downloadExtraFile(sampleDataURL).then(blob => blob.text());
            this.tmTrainingInstance.load(modelData, JSON.parse(sampleDataJSON));
        } catch (e) {
            console.warn('Failed to load sample data. Only model data will be loaded.');
            this.tmTrainingInstance.load(modelData);
        }
        this.props.setHiddenWorkLoader();
        this.setState({
            classIds: this.tmTrainingInstance.getClassIds()
        });
    }

    onAddNewSample () {
        this.setState({ isHaveNewSample: true });
    }

    getSampleDataPath (sampleDataId) {
        return `extension_file_archive/tm_sample_data/${sampleDataId}`;
    }

    render () {
        return (<TmTrainingModalComponent
            key={this.state.componentKey}
            tmTrainingInstance={this.tmTrainingInstance}
            cameraViewOpenedClassLabel={this.state.cameraViewOpenedClassLabel}
            onOpenCameraViewOfClass={this.onOpenCameraViewOfClass}
            onCloseCameraViewOfClass={this.onCloseCameraViewOfClass}
            onCloseTmTrainingModal={this.onCloseModal}
            onDeviceChange={this.onDeviceChange}
            onApplyModel={this.onApplyModel}
            onSaveSample={this.onSaveSample}
            isCameraViewOpened={this.isCameraViewOpened}
            classIds={this.state.classIds}
            uploadedSampleLink={this.state.uploadedSampleLink}
            onAddNewClass={this.onAddNewClass}
            onDeleteSample={this.onDeleteSample}
            onClassMenuToggle={this.onClassMenuToggle}
            isClassMenuOpened={this.isClassMenuOpened}
            clearClass={this.clearClass}
            deleteClass={this.deleteClass}
            canDeleteAnyClass={this.tmTrainingInstance.canDeleteAnyClass()}
            isProjectMenuOpen={this.state.isProjectMenuOpen}
            onOpenProjectMenu={this.openProjectMenu}
            onCloseProjectMenu={this.closeProjectMenu}
            onOpenNewProject={this.openNewProject}
            onAddNewSample={this.onAddNewSample}
            isHaveNewSample={this.state.isHaveNewSample}
            onSaveProject={this.onSaveSampleLocal}
            onLoadProject={this.onLoadSampleLocal}
        />);
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    onCloseTmTrainingModal: () => {
        dispatch(closeTMTrainingModal());
    },
    setVisibleWorkLoader: () => {
        dispatch(setVisible('teachableMachine', 'Loading...'));
    },
    setHiddenWorkLoader: () => {
        dispatch(setHidden());
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TmTrainingModal);
