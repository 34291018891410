import React from 'react';
import PropTypes from 'prop-types';
import TeachableMachineComponent from '../components/teachable-machine/teachable-machine.jsx';
import VM from 'scratch-vm';
import bindAll from 'lodash.bindall';
import {defineMessages, intlShape, injectIntl} from 'react-intl';
import TmTraining from '../../tm-training/dist/tm-training.js';
import { downloadExtraFile } from '../semobae_utils/semobae_utils.js';
import { setVisible, setHidden } from '../reducers/work-loading-state.js';
import { connect } from 'react-redux';

const messages = defineMessages({
    title: {
        id: 'gui.extension.teachableMachine.modalTitle',
        defaultMessage: 'Teachable Machine',
        description: 'teachable machine title'
    },
    placeholder: {
        id: 'gui.extension.teachableMachine.modalPlaceholder',
        defaultMessage: 'Please enter the url',
        description: 'teachable machine placeholder'
    },
    modalDescription: {
        id: 'gui.extension.teachableMachine.modalDescription',
        defaultMessage: 'Teachable machine Model URL',
        description: 'teachable machine modal sub title'
    }
});
class CustomTeachableMachine extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, ['handleCancel', 'handleChange', 'handleOk', 'alertAudioModel','handleCheckOfficialModel']);

        this.state = {
            url: null,
            isAudioModel: false,
            checkOfficalModel: false,
        };
    }

    getFormattedTmUrl (unformattedURL) {
        const trimmedURL = unformattedURL.trim();
        return trimmedURL.charAt(trimmedURL.length - 1) === '/' ? trimmedURL : trimmedURL + '/';
    }

    async handleOk () {
        this.setState({isAudioModel: false});
        if (!this.state.url) {
            alert('URL을 입력해주세요.');
            return;
        }

        if (this.state.checkOfficalModel) {
            const formattedURL = this.getFormattedTmUrl(this.state.url);
            this.props.vm.extensionManager.setTeachableMachineBlocksWithUrl(formattedURL)
                .then(() => {
                    this.props.onDialogClose();
                })
                .catch(error => {
                    if (error.message === 'AudioModelError') {
                        this.alertAudioModel();
                        return;
                    }
                    alert('잘못된 URL입니다. 확인 후 다시 입력해주세요.');
                    console.error(error);
                });
            return;
        }

        const sampleDataURL = this.state.url;
        const sampleDataJSON = await downloadExtraFile(sampleDataURL)
            .then(blob => blob.text())
            .catch((err) => {
                console.error(err);
                alert('잘못된 URL입니다. 확인 후 다시 입력해주세요.');
            });

        const tm = new TmTraining();
        tm.init()
            .then(async () => {
                this.props.setVisibleWorkLoader();
                await tm.loadSampleData(JSON.parse(sampleDataJSON));
                const modelData = tm.getModelData();
                await this.props.vm.extensionManager.setTeachableMachineBlocksWithOfflineModel(modelData);
                this.props.vm.runtime.teachableMachineSampleDataURL = sampleDataURL;
                this.props.vm.runtime.emitProjectChanged();
                this.props.onDialogClose();
            }).catch((err) => {
                console.error(err);
                alert('모델 데이터를 가져오는데 실패했습니다.');
            }).finally(() => {
                this.props.setHiddenWorkLoader();
            });
    }
    handleCancel () {
        this.props.onDialogClose();
        return;
    }
    handleChange (e) {
        this.setState({url: e.target.value});
        return;
    }
    alertAudioModel () {
        this.setState({isAudioModel: true});
    }

    handleCheckOfficialModel (e) {
        this.state.checkOfficalModel = e.target.checked;
    }

    render () {
        const {intl} = this.props;
        return (
            <TeachableMachineComponent
                placeholder={intl.formatMessage(messages.placeholder)}
                label={intl.formatMessage(messages.modalDescription)}
                onCancel={this.handleCancel}
                onChange={this.handleChange}
                onOk={this.handleOk}
                title={intl.formatMessage(messages.title)}
                isAudioModel={this.state.isAudioModel}
                vm={this.props.vm}
                handleCheckOfficialModel={this.handleCheckOfficialModel}
            />
        );
    }
}

CustomTeachableMachine.propTypes = {
    vm: PropTypes.PropTypes.instanceOf(VM),
    onDialogClose: PropTypes.func.isRequired,
    intl: intlShape.isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    onCloseTmTrainingModal: () => {
        dispatch(closeTMTrainingModal());
    },
    setVisibleWorkLoader: () => {
        dispatch(setVisible('teachableMachine', 'Loading...'));
    },
    setHiddenWorkLoader: () => {
        dispatch(setHidden());
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CustomTeachableMachine));
